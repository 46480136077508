












































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { difference, isEqual, uniq, xor } from "lodash";

import TrackerPickerInput from "@/shared/components/pickers/TrackerPickerInput.vue";
import {
  DictionaryType,
  TrackerPickerModel,
  ValidationRule,
  FilterPreviewId,
  TrackerType,
  TrackerOrigin,
} from "@/shared/models";

@Component({
  components: {
    TrackerPickerInput,
  },
})
export default class TrackerPicker extends Vue {
  @Prop() value!: TrackerPickerModel;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop({ default: true }) hasSubSource!: boolean;
  @Prop() applicationId?: string;
  @Prop() rules?: Array<ValidationRule>;
  @Prop({ default: () => [] }) errorMessages!: Array<string>;
  @Prop({ default: false }) isAttached!: boolean;
  @Prop() filterPreviewId?: boolean;
  @Prop() itemsFilter?: (item: any, currentValue: any) => boolean;
  @Prop() origins?: Array<TrackerOrigin>;

  readonly FilterPreviewId = FilterPreviewId;
  readonly DictionaryType = DictionaryType;
  localValue = TrackerPickerModel.of(this.value);
  subSourcesVisible = false;
  campaignsVisible = false;
  adSetsVisible = false;
  creativesVisible = false;
  publishersVisible = false;

  get sourceChangeLoaderVisible(): boolean {
    return (
      (this.$store.state.trackerStore.loadingTracker[
        DictionaryType.SUB_SOURCES
      ] ||
        this.$store.state.trackerStore.loadingTracker[
          DictionaryType.CAMPAIGNS
        ]) &&
      !this.subSourcesVisible &&
      !this.campaignsVisible &&
      !this.adSetsVisible &&
      !this.creativesVisible &&
      !this.publishersVisible
    );
  }

  get adSetChangeLoaderVisible(): boolean {
    return (
      this.$store.state.trackerStore.loadingTracker[DictionaryType.AD_SETS] &&
      !this.adSetsVisible &&
      !this.creativesVisible &&
      !this.publishersVisible
    );
  }

  get creativeAndPublisherChangeLoaderVisible(): boolean {
    return (
      (this.$store.state.trackerStore.loadingTracker[
        DictionaryType.CREATIVES
      ] ||
        this.$store.state.trackerStore.loadingTracker[
          DictionaryType.PUBLISHERS
        ]) &&
      !this.creativesVisible &&
      !this.publishersVisible
    );
  }

  @Watch("value")
  watchValue(newValue: TrackerPickerModel) {
    if (isEqual(newValue, this.localValue)) {
      return;
    }

    this.localValue = TrackerPickerModel.of(newValue);
  }

  @Watch("localValue")
  async watchLocalValue(newValue: TrackerPickerModel) {
    if (!newValue[DictionaryType.SOURCES].length) {
      this.$emit("input", new TrackerPickerModel());

      return;
    }

    const typesToLoad: Array<TrackerType> = [];
    const tracker = TrackerPickerModel.of(newValue);
    const adSetDependedType: Array<TrackerType> = [
      DictionaryType.CREATIVES,
      DictionaryType.PUBLISHERS,
    ];
    const campaingDependedTypes: Array<TrackerType> = [
      DictionaryType.AD_SETS,
      ...adSetDependedType,
    ];
    const sourceDependedTypes: Array<TrackerType> = [
      ...((this.hasSubSource
        ? [DictionaryType.SUB_SOURCES]
        : []) as Array<TrackerType>),
      DictionaryType.CAMPAIGNS,
      ...campaingDependedTypes,
    ];

    if (
      tracker.included[DictionaryType.SOURCES] !==
      this.value.included[DictionaryType.SOURCES]
    ) {
      if (!tracker.included[DictionaryType.SOURCES]) {
        tracker.resetValues(DictionaryType.SOURCES);
        this.$emit("input", tracker);

        return;
      }

      typesToLoad.push(...sourceDependedTypes);
    }

    if (
      xor(tracker[DictionaryType.SOURCES], this.value[DictionaryType.SOURCES])
        .length
    ) {
      const deletedSources = difference(
        this.value[DictionaryType.SOURCES],
        tracker[DictionaryType.SOURCES]
      );

      typesToLoad.push(...sourceDependedTypes);

      if (deletedSources.length) {
        sourceDependedTypes.forEach((type) => {
          tracker.filterDependedValues(
            type as TrackerType,
            this.$store.state.trackerStore.dataTrackers[type].values,
            deletedSources
          );
        });
      }
    }

    if (
      tracker.included[DictionaryType.CAMPAIGNS] !==
      this.value.included[DictionaryType.CAMPAIGNS]
    ) {
      if (!tracker.included[DictionaryType.CAMPAIGNS]) {
        tracker.resetValues(DictionaryType.CAMPAIGNS);
        this.$emit("input", tracker);

        return;
      }

      typesToLoad.push(...campaingDependedTypes);
    }

    if (xor(tracker.campaigns, this.value.campaigns).length) {
      const deletedCampaigns = difference(
        this.value.campaigns,
        tracker.campaigns
      );

      typesToLoad.push(...campaingDependedTypes);

      if (deletedCampaigns.length) {
        campaingDependedTypes.forEach((type) => {
          tracker.filterDependedValues(
            type as TrackerType,
            this.$store.state.trackerStore.dataTrackers[type].values,
            deletedCampaigns
          );
        });
      }
    }

    if (
      tracker.included[DictionaryType.AD_SETS] !==
      this.value.included[DictionaryType.AD_SETS]
    ) {
      if (!tracker.included[DictionaryType.AD_SETS]) {
        tracker.resetValues(DictionaryType.AD_SETS);
        this.$emit("input", tracker);

        return;
      }

      typesToLoad.push(...adSetDependedType);
    }

    if (xor(tracker.adSets, this.value.adSets).length) {
      const deletedAdSets = difference(this.value.campaigns, tracker.campaigns);

      typesToLoad.push(...adSetDependedType);

      if (deletedAdSets.length) {
        adSetDependedType.forEach((type) => {
          tracker.filterDependedValues(
            type as TrackerType,
            this.$store.state.trackerStore.dataTrackers[type].values,
            deletedAdSets
          );
        });
      }
    }

    this.loadTrackers(tracker, uniq(typesToLoad));
    this.$emit("input", tracker);
  }

  @Watch("applicationId")
  watchApplicationId() {
    this.$store.commit("clearAllTrackers");
    this.localValue = new TrackerPickerModel();
    this.loadTracker(DictionaryType.SOURCES);
  }

  created() {
    const typesToLoad: Array<TrackerType> = [DictionaryType.SOURCES];

    if (
      this.value[DictionaryType.SOURCES].length &&
      this.value.included[DictionaryType.SOURCES]
    ) {
      typesToLoad.push(DictionaryType.CAMPAIGNS);

      if (this.hasSubSource) {
        typesToLoad.push(DictionaryType.SUB_SOURCES);
      }

      if (this.value.included[DictionaryType.CAMPAIGNS]) {
        typesToLoad.push(DictionaryType.AD_SETS);
      }

      if (this.value.included[DictionaryType.AD_SETS]) {
        typesToLoad.push(DictionaryType.CREATIVES, DictionaryType.PUBLISHERS);
      }
    }

    this.loadTrackers(this.value, typesToLoad);
  }

  beforeDestroy() {
    this.$store.commit("clearAllTrackers");
  }

  loadTrackers(tracker: TrackerPickerModel, typesToLoad: Array<TrackerType>) {
    Promise.all(
      typesToLoad.map((type) =>
        this.loadTracker(type, tracker.getParentValues(type))
      )
    );
  }

  loadTracker(type: DictionaryType, parentValues?: Array<string>) {
    if (!this.applicationId) {
      return;
    }

    return this.$store.dispatch("loadTracker", {
      applicationId: this.applicationId,
      type,
      parentValues,
      origins: this.origins,
    });
  }
}
